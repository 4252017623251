import React from "react";

import { Container } from "react-bootstrap";

import Page from "components/Page/Page";

const config = [
  {
    name: "Hero Image",
    moduleComponentName: "HeroImage",
    moduleProps: { style: { height: "55vh" } },
    rowProps: { noGutters: true },
  },
  {
    name: "Home First Time Modal",
    moduleComponentName: "FirstTimeModal",
    moduleProps: { pageName: "home", targetModalId: "intro" },
  },
  {
    name: "Hero Image Links",
    children: [
      {
        name: "image link 1",
        columnProps: {
          xs: 12,
          sm: 6,
          md: 4,
          className: "px-4 mt-4 mt-md-n5",
        },
        moduleComponentName: "ImageLink",
        moduleProps: {
          alignCenter: true,
          belowLinkText: `
                  A new metro railway line connecting communities with the new
                  Western Sydney International airport and Greater Western Sydney.
                `,
          fileName: "image-link-1",
          url: { path: "/interactive-map" },
          imgStyle: { height: 125, width: 125 },
          textStyle: {
            color: "white",
            fontWeight: "bold",
            paddingTop: 16,
            paddingBottom: 16,
          },
        },
      },
      {
        name: "image link 2",
        columnProps: {
          xs: 12,
          sm: 6,
          md: 4,
          className: "px-4 mt-md-n5",
        },
        moduleComponentName: "ImageLink",
        moduleProps: {
          alignCenter: true,
          belowLinkText: `
                  A travel time target of around 20 minutes between St Marys
                  Station and the Aerotropolis Station.
                `,
          fileName: "image-link-2",
          url: { path: "/interactive-map" },
          imgStyle: { height: 125, width: 125 },
          textStyle: {
            color: "white",
            fontWeight: "bold",
            paddingTop: 16,
            paddingBottom: 16,
          },
        },
      },
      {
        name: "image link 3",
        columnProps: {
          xs: 12,
          sm: 6,
          md: 4,
          className: "px-4 mt-md-n5",
        },
        moduleComponentName: "ImageLink",
        moduleProps: {
          alignCenter: true,
          belowLinkText: `
                  Six new metro stations, supporting 14,000 jobs and significant
                  growth in Western Sydney.
                `,
          fileName: "image-link-3",
          url: { path: "/interactive-map" },
          imgStyle: { height: 125, width: 125 },
          textStyle: {
            color: "white",
            fontWeight: "bold",
            paddingTop: 16,
            paddingBottom: 16,
          },
        },
      },
    ],
    rowProps: {
      noGutters: true,
      className: "bg-primary",
    },
    wrapWithContainer: true,
  },
  {
    name: "About Sydney Metro – Western Sydney Airport",
    children: [
      {
        name: "About us heading",
        columnProps: { xs: 12, className: "mb-3" },
        moduleComponentName: "SectionHeading",
        moduleProps: {
          heading: {
            priority: 1,
            text: "About Sydney Metro – Western Sydney Airport",
          },
        },
      },
      {
        name: "About us text",
        columnProps: { xs: 12 },
        moduleComponentName: "SectionText",
        moduleProps: {
          className: "text-dark",
          text: [
            `
              A new metro railway line will become the transport spine for
              Greater Western Sydney, connecting communities and travellers with
              the new Western Sydney International (Nancy-Bird Walton) Airport and
              the growing region. The project was approved by the NSW Government in
              July 2021, with major construction set to start later in the year.
            `,
            `
              This city-shaping project will become the transport spine for the
              growing region, connecting communities with the new airport,
              jobs and services and delivering better access to employment
              opportunities, health and education facilities and leisure
              activities across Greater Sydney.
            `,
            `
              The Sydney Metro – Western Sydney Airport project will deliver
              about 23 kilometres of rail between St Marys metro station and the future
              Western Sydney Aerotropolis in Bringelly (the area known as Bradfield).
            `,
            `
              Six stations will be built along the alignment including two stations at
              Western Sydney International airport, one at the Western Sydney Aerotropolis
              and one at St Marys – here customers will be able to connect to the existing
              Sydney Trains suburban T1 Western Line. Stations at Orchard Hills and
              Luddenham will service a future mixed-use precinct and an education
              and innovation precinct.
            `,
          ],
        },
      },
    ],
    rowProps: {
      noGutters: true,
      className: "pt-3 pt-md-5 bg-secondary",
    },
    wrapWithContainer: true,
  },
  {
    name: "About Section Card Links",
    children: [
      {
        name: "Link 1",
        columnProps: {
          xs: 12,
          md: 6,
          lg: 3,
          className: "d-flex align-items-stretch mb-3 pr-sm-2",
        },
        moduleComponentName: "CardImageLink",
        moduleProps: {
          fileName: "project-overview-animation",
          imgStyle: { height: 200, objectFit: "cover", marginBottom: 0 },
          title: { text: "Luddenham Station animation" },
          text: {
            text: `View the animated overview of Luddenham Station.`,
          },
          isLightboxTrigger: true,
          lightboxProps: {
            lightboxSources: [
              {
                type: "video",
                title: "Luddenham Station animation",
                src: "https://player.vimeo.com/video/469652460?app_id=122963",
              },
            ],
            startingLightboxIndex: 0,
          },
        },
      },
      {
        name: "Link 2",
        columnProps: {
          xs: 12,
          md: 6,
          lg: 3,
          className: "d-flex align-items-stretch mb-3 pr-sm-2",
        },
        moduleComponentName: "CardImageLink",
        moduleProps: {
          fileName: "interactive-map-link",
          imgStyle: { height: 200, objectFit: "cover", marginBottom: 0 },
          title: { text: "Interactive map" },
          text: {
            text: `
              Learn more about the project and the EIS through an
              interactive map.
            `,
          },
          url: { path: "/interactive-map" },
        },
      },
      {
        name: "Link 3",
        columnProps: {
          xs: 12,
          md: 6,
          lg: 3,
          className: "d-flex align-items-stretch mb-3 pr-sm-2",
        },
        moduleComponentName: "CardImageLink",
        moduleProps: {
          fileName: "virtual-information-room-link",
          imgStyle: { height: 200, objectFit: "cover", marginBottom: 0 },
          title: { text: "Virtual information room" },
          text: {
            text: `
              Navigate through our virtual information room to learn more about
              the project.
            `,
          },
          url: { path: "/virtual-information-room" },
        },
      },
      {
        name: "Link 4",
        columnProps: {
          xs: 12,
          md: 6,
          lg: 3,
          className: "d-flex align-items-stretch mb-3 pr-sm-2",
        },
        moduleComponentName: "CardImageLink",
        moduleProps: {
          fileName: "hear-from-our-experts",
          imgStyle: { height: 200, objectFit: "cover", marginBottom: 0 },
          title: { text: "Hear from our experts" },
          text: {
            text: `
              Watch short videos from our team of experts about key aspects of the
              project.
            `,
          },
          url: { path: "/images-and-videos#project-expert-videos" },
        },
      },
    ],
    rowProps: {
      noGutters: true,
      className: "pb-md-5 bg-secondary",
    },
    wrapWithContainer: true,
  },
];

const AboutPage = () => {
  return (
    <Container fluid className="page-view">
      <Page config={config} />
    </Container>
  );
};

export default AboutPage;
